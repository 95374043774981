/*jshint esversion: 6 */
/*jshint strict:false */

export default function () {
    return {
        restrict: 'E',
        require: '^ngModel',
        scope: {
            ngModel: '=',
            options: '=',
            propertyName: '@',
            other: '@'
        },
        replace: true,
        templateUrl: 'editable-select-tpl.html',
        link: function (scope, element) {
            scope.isDisabled = true;

            scope.click = function (option) {
                scope.ngModel = option;
                scope.isDisabled = !scope.other || scope.other !== option;
                if (!scope.isDisabled) {
                    element[0].querySelector('.editable-select').focus();
                }
            };

            var unwatch = scope.$watch('ngModel', function (val) {
                if (!scope.isDisabled) {
                    scope.other = scope.ngModel;
                }
            });

            scope.$on('$destroy', unwatch);
        }
    };
}