/*jshint esversion: 6 */
/*jshint strict:false */

import viewerCallback from "./services/viewerCallback";
import editableSelect from "./directives/editableSelect";

let sbtCommon = angular.module('sbt.common', [])
    .directive('editableSelect', editableSelect)
    .service('viewerCallback', viewerCallback)
    .run(["$templateCache", function($templateCache) {
    $templateCache.put("editable-select-tpl.html","<div class=\"editable-select\" style=\"width: 160px;\"><div class=\"input-group dropdown\"><input name=\"editable-select\" type=\"number\" min=\"0\" class=\"form-control dropdown-toggle editable-select\" ng-model=\"ngModel\"><ul class=\"dropdown-menu\"><li ng-repeat=\"option in options\" ng-bind=\"::option.name\" ng-click=\"click(option.id)\"></li></ul><span class=\"input-group-addon dropdown-toggle\" data-toggle=\"dropdown\"><span class=\"caret\"></span></span></div></div>");
}]);