export default function ($rootScope, $modal, $q, gettextCatalog) {
    this.open = function (title, required, options) {
        var modal_scope = $rootScope.$new(true),
            deferred = $q.defer(),
            required = typeof(required) == 'undefined' ? false : required,
            options = typeof(options) == 'undefined' ? {} : options;
        options.contentTemplate = typeof(options.contentTemplate) == 'undefined' ? '/static/templates/confirm.html' : options.contentTemplate;
        options.data = typeof(options.data) == 'undefined' ? {} : options.data;
        modal_scope.custom_title = title;
        modal_scope.footerVisible = false;
        modal_scope.data = options.data;
        if (required) modal_scope.hideCancel = true;
        modal_scope.on_press_ok = function () {
            cnf_modal.$promise.then(cnf_modal.hide);
            deferred.resolve(modal_scope);
        };
        var cnf_modal = $modal({
            scope: modal_scope,
            templateUrl: '/static/templates/modal_tpl.html',
            contentTemplate: options.contentTemplate,
            container: "body",
            title: "."
        });
        modal_scope.$on('modal.show', function () {
            angular.element('#confirm_input_text').focus();
        });
        return deferred.promise;
    }
}